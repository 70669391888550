<template>
    <div class="p-grid p-fluid home">
	<div style="left: -1rem;">
		<Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" 
			:circular="true" :autoPlay="true" :showThumbnails="false" :showIndicators="true" :changeItemOnIndicatorHover="true" :showIndicatorsOnItem="true" indicatorsPosition="left">
			<template #item="slotProps">
				<img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;"/>
			</template>
                    <template #caption="{item}" class="home">
						<div :class="introductionClass">
						<div class="introduction-promo">{{$t('introduction-promo')}}</div>
                        <div class="introduction-title">{{$t('introduction-title')}}</div>
						<img alt="devices" class="introduction-devices" src="../assets/images/logo2.png"/>
						<p>{{item.alt}}</p>
						</div>
                    </template>

		</Galleria>	
	</div>

    <div class="home">

        <div class="templates">
            <div class="p-grid">
                <div class="p-col-12 p-md-3">
                    <h4 style="color:#727376;"><strong>{{$t('Report Claim')}}</strong></h4>
                    <a href="#">
                        <img src="../assets/images/claim.webp" width="220" >
                    </a>
                    <h5 style="color:#ffffff;"><strong>{{$t('Report Claim Mens')}}</strong></h5>
                </div>
                <div class="p-col-12 p-md-3">
                    <h4 style="color:#727376;"><strong>{{$t('Get a Quote')}}</strong></h4>
                    <a href="#">
                        <img src="../assets/images/quote.webp" width="220" >
                    </a>
                    <h5 style="color:#ffffff;"><strong>{{$t('Get a Quote Mens')}}</strong></h5>
                </div>
           
                <div class="p-col-12 p-md-3">
                    <h4 style="color:#727376;"><strong>{{$t('Payment Method')}}</strong></h4>
                    <a href="#">
                        <img src="../assets/images/payment.webp" width="220">
                    </a>
                    <h5 style="color:#ffffff;"><strong>{{$t('Payment Method Mens')}}</strong></h5>
                </div>
                <div class="p-col-12 p-md-3">
                    <h4 style="color:#727376;"><strong>{{$t('Customer Support')}}</strong></h4>
                    <a href="#">
                        <img src="../assets/images/support.webp" width="220">
                    </a>
                    <h5 style="color:#ffffff;"><strong>{{$t('Customer Support Mens')}}</strong></h5>
                </div>
            </div>
        </div>
		
        <Services/>

		<Company style="padding: 3.5em 0em;"/>

	</div>


</div>
</template>

<script>
import Company from './Company.vue';
import Services from './Services.vue';

export default {
	components: {
        Company,
        Services,
    },
	data() {
		return {
            dark: false,
            images: [ 
				{ 
					"itemImageSrc": "images/banner1.webp", 
					"thumbnailImageSrc": "images/banner1.webp", 
					"alt": "", 
					"title": "Banner 1" 
				}, 
				{ 
					"itemImageSrc": "images/banner2.webp", 
					"thumbnailImageSrc": "images/banner2.webp", 
					"alt": "", 
					"title": "Banner 2" 
				}, 
				{ 
					"itemImageSrc": "images/banner3.webp", 
					"thumbnailImageSrc": "images/banner3.webp", 
					"alt": "", 
					"title": "Banner 2" 
				}
			],
			responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
			]
			
		}
	},
	productService: null,
	eventService: null,
	created() {
	},
	mounted() {
	},
	methods: {
        Company() {
            this.$router.push({ path: '/company' });
        }

	},
	computed: {
        introductionClass() {
            return ['introduction', {'introduction-dark': true}];
        }
    }

}
</script>
